<template>
  <div>
    <a-spin v-if="!nativeAdData" class="d-flex justify-content-center align-items-center ">
      <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
    </a-spin>
    <div v-else class="row">
      <div class="col-lg-12">
        <a @click="goBack" class="margin-bottom" style="border: 1px black"><span class="fa fa-long-arrow-left"></span>
          Back</a>
        <br />
        <br />
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Edit Native Ad</strong>
            </div>
            <div class="text-muted">Edit Native Ad information</div>
          </div>
          <div class="card-body">
            <a-form :form="form" @submit.prevent="handleSubmit">
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Native Ad's name.</span>
                      </template>
                      <a-input type="text" placeholder="Native Ad Name" v-decorator="['Name', {
                        initialValue: nativeAdData.Name,
                        rules: [
                          { required: true, message: 'Name is required.' },
                          { max: 64, message: 'Maximum 64 characters allowed.' }
                        ]
                      }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Default CPM">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Default cost per mile.</span>
                      </template>
                      <a-input type="number" min="0" max="100" step="0.0001" placeholder="Default CPM"
                        @change="updateDefaultCPM"
                        v-decorator="['defaultCPM', { initialValue: Math.round((nativeAdData.DefaultCPM + Number.EPSILON) * 10000) / 10000, rules: [{ validator: defaultCpmValidator }] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad Domain/Brand">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Domain or brand name for your Native Ad.</span>
                      </template>
                      <a-input placeholder="Ad Domain/Brand" v-decorator="['adDomainBrand', {
                        initialValue: nativeAdData.AdDomainBrand,
                        rules: [
                          { max: 256, message: 'Maximum 256 characters allowed.' }
                        ]
                      }]">
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad App Bundle">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Application identifier. On Android, this should be bundle or package name (e.g.,
                          com.foo.mygame), on iOS, this is numeric ID.</span>
                      </template>
                      <a-input type="text" placeholder="Ad App Bundle" v-decorator="['adAppBundle', {
                        initialValue: nativeAdData.AdAppBundle,
                        rules: [
                          { max: 64, message: 'Maximum 64 characters allowed.' }
                        ]
                      }]">
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Destination URL">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Destination URL that loads when user clicks your Native Ad.</span>
                      </template>
                      <a-input type="url" placeholder="Destination URL" v-decorator="['destinationURL', {
                        initialValue: nativeAdData.DestinationUrl,
                        rules: [
                          { required: true, message: 'Destination URL is required.' },
                          { max: 512, message: 'Maximum 512 characters allowed.' }
                        ]
                      }]">
                        <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
                          <template slot="title">
                            <b>{cc}</b> - Lowercase country code will be replaced.<br>
                            <b>{CC}</b> - Uppercase country code will be replaced.<br>
                            <b>{Country}</b> - Capitalized country name will be replaced.<br>
                            <b>{ip}</b> - IP will be replaced.<br>
                            <b>{ua}</b> - User-agent string will be replaced.<br>
                            <b>{site_url}</b> - Site URL will be replaced.<br>
                            <b>{app_bundle}</b> - App Bundle will be replaced.<br>
                            <b>{app_name}</b> - App Name will be replaced.<br>
                            <b>{app_store_url}</b> - App Store URL will be replaced.<br>
                            <b>{cb}</b> - Cache buster: a uniquely generated character sequence for each link in order to prevent page caching.<br>
                            <b>{timestamp}</b> - Timestamp generated at the time of transaction.<br>
                            <b>{schain}</b> - Serialized schain parameter.<br>
                            <b>{lat}</b> - Latitude will be replaced.<br>
                            <b>{lon}</b> - Longitude will be replaced.<br>
                            <b>{ifa}</b> - Identifier for Advertisers will be replaced.<br>
                            <b>{cid}</b> - Click ID will be replaced.<br>
                          </template>
                          <a-icon type="code" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Click  Trackers">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of third-party tracker URLs to be fired on click of the URL.</span>
                      </template>
                      <div class="list-flex">
                        <a-select
                          v-decorator="['linkClicktrackers', { initialValue: nativeAdData.LinkClicktrackers.length === 0 ? [] : nativeAdData.LinkClicktrackers }]"
                          @change="handlelinkClicktrackers" placeholder="Click Trackers" :filterOption="true"
                          optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('linkClicktrackers')"><i
                            class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Fallback URL">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Fallback URL for deeplink. To be used if the URL given in Destination URL is not supported
                          by the device.</span>
                      </template>
                      <a-input type="url" placeholder="Fallback URL" v-decorator="['linkFallback', {
                        initialValue: nativeAdData.LinkFallback,
                        rules: [
                          { max: 512, message: 'Maximum 512 characters allowed.' }
                        ]
                      }]">
                        <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
                          <template slot="title">
                            <b>{cc}</b> - Lowercase country code will be replaced.<br>
                            <b>{CC}</b> - Uppercase country code will be replaced.<br>
                            <b>{Country}</b> - Capitalized country name will be replaced.<br>
                            <b>{ip}</b> - IP will be replaced.<br>
                            <b>{ua}</b> - User-agent string will be replaced.<br>
                            <b>{site_url}</b> - Site URL will be replaced.<br>
                            <b>{app_bundle}</b> - App Bundle will be replaced.<br>
                            <b>{app_name}</b> - App Name will be replaced.<br>
                            <b>{app_store_url}</b> - App Store URL will be replaced.<br>
                            <b>{cb}</b> - Cache buster: a uniquely generated character sequence for each link in order to prevent page caching.<br>
                            <b>{timestamp}</b> - Timestamp generated at the time of transaction.<br>
                            <b>{schain}</b> - Serialized schain parameter.<br>
                            <b>{lat}</b> - Latitude will be replaced.<br>
                            <b>{lon}</b> - Longitude will be replaced.<br>
                            <b>{ifa}</b> - Identifier for Advertisers will be replaced.<br>
                            <b>{cid}</b> - Click ID will be replaced.<br>
                          </template>
                          <a-icon type="code" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Imp. Trackers">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Impression tracking URLs, expected to return a 1x1 image or 204 response - typically only
                          passed when using 3rd party trackers.</span>
                      </template>
                      <div class="list-flex">
                        <a-select
                          v-decorator="['imptrackers', { initialValue: nativeAdData.Imptrackers.length === 0 ? [] : nativeAdData.Imptrackers }]"
                          @change="handleImptrackers" placeholder="Impression Trackers" :filterOption="true"
                          optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('impTrackers')"><i
                            class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="JS Tracker">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Optional JavaScript impression tracker. This is a valid HTML, Javascript is already wrapped
                          in &lt;script&gt; tags. It should be executed at impression time where it can be
                          supported.</span>
                      </template>
                      <a-textarea :auto-size="{ minRows: 2 }"
                        v-decorator="['jstracker', { initialValue: nativeAdData.Jstracker }]"
                        style="margin-bottom: 0.75em" placeholder="JavaScript Tracker HTML" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Privacy URL">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Privacy notice URL.</span>
                      </template>
                      <a-input type="url" placeholder="Privacy URL" v-decorator="['privacyUrl', {
                        initialValue: nativeAdData.PrivacyUrl,
                        rules: [
                          { max: 512, message: 'Maximum 512 characters allowed.' }
                        ]
                      }]">
                        <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
                          <template slot="title">
                            <b>{cc}</b> - Lowercase country code will be replaced.<br>
                            <b>{CC}</b> - Uppercase country code will be replaced.<br>
                            <b>{Country}</b> - Capitalized country name will be replaced.<br>
                            <b>{ip}</b> - IP will be replaced.<br>
                            <b>{ua}</b> - User-agent string will be replaced.<br>
                            <b>{site_url}</b> - Site URL will be replaced.<br>
                            <b>{app_bundle}</b> - App Bundle will be replaced.<br>
                            <b>{app_name}</b> - App Name will be replaced.<br>
                            <b>{app_store_url}</b> - App Store URL will be replaced.<br>
                            <b>{cb}</b> - Cache buster: a uniquely generated character sequence for each link in order to prevent page caching.<br>
                            <b>{timestamp}</b> - Timestamp generated at the time of transaction.<br>
                            <b>{schain}</b> - Serialized schain parameter.<br>
                            <b>{lat}</b> - Latitude will be replaced.<br>
                            <b>{lon}</b> - Longitude will be replaced.<br>
                            <b>{ifa}</b> - Identifier for Advertisers will be replaced.<br>
                            <b>{cid}</b> - Click ID will be replaced.<br>
                          </template>
                          <a-icon type="code" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;"></a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <!-- left column -->
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Layout">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>The Layout ID of the native ad unit. Used for version <b>1.0</b> of Dynamic Native Ads
                          API.</span>
                      </template>
                      <a-select placeholder="Select Layout" v-decorator="['layout', {
                        initialValue: nativeAdData.Layout,
                      }]">
                        <a-select-option :value="-1">Any</a-select-option>
                        <a-select-option :value="1">Content Wall</a-select-option>
                        <a-select-option :value="2">App Wall</a-select-option>
                        <a-select-option :value="3">News Feed</a-select-option>
                        <a-select-option :value="4">Chat List</a-select-option>
                        <a-select-option :value="5">Carousel</a-select-option>
                        <a-select-option :value="6">Content Stream</a-select-option>
                        <a-select-option :value="7">Grid adjoining the content</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad Unit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>The Ad unit ID of the native ad unit. Used for version <b>1.0</b> of Dynamic Native Ads
                          API.</span>
                      </template>
                      <a-select placeholder="Select Ad Unit" v-decorator="['adUnit', {
                        initialValue: nativeAdData.AdUnit,
                      }]">
                        <a-select-option :value="-1">Any</a-select-option>
                        <a-select-option :value="1">Paid Search Units</a-select-option>
                        <a-select-option :value="2">Recommendation Widgets</a-select-option>
                        <a-select-option :value="3">Promoted Listings</a-select-option>
                        <a-select-option :value="4">In-Ad (IAB Standard) with Native Element Units</a-select-option>
                        <a-select-option :value="5">Custom/Can't Be Contained</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Context">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>The context in which the ad appears.</span>
                      </template>
                      <a-select placeholder="Select Context" @change="handleContextChange" v-model="contextValue">
                        <a-select-option :value="-1">Any</a-select-option>
                        <a-select-option :value="1">Content</a-select-option>
                        <a-select-option :value="2">Social</a-select-option>
                        <a-select-option :value="3">Product</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <!-- right column -->
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Context Subtype">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>A more detailed context in which the ad appears.</span>
                      </template>
                      <a-select placeholder="Select Context Subtype" v-model="contextSubtype">
                        <a-select-option :disabled="false" :value="-1">Any</a-select-option>
                        <a-select-option :disabled="contextValue !== 1" :value="10">General or mixed
                          content.</a-select-option>
                        <a-select-option :disabled="contextValue !== 1" :value="11">Primarily article content (which of
                          course could include images, etc as part of the article)</a-select-option>
                        <a-select-option :disabled="contextValue !== 1" :value="12">Primarily video
                          content</a-select-option>
                        <a-select-option :disabled="contextValue !== 1" :value="13">Primarily audio
                          content</a-select-option>
                        <a-select-option :disabled="contextValue !== 1" :value="14">Primarily image
                          content</a-select-option>
                        <a-select-option :disabled="contextValue !== 1" :value="15">User-generated content - forums,
                          comments, etc.</a-select-option>
                        <a-select-option :disabled="contextValue !== 2" :value="20">General social content such as a
                          general social network</a-select-option>
                        <a-select-option :disabled="contextValue !== 2" :value="21">Primarily email
                          content</a-select-option>
                        <a-select-option :disabled="contextValue !== 2" :value="22">Primarily chat/IM
                          content</a-select-option>
                        <a-select-option :disabled="contextValue !== 3" :value="30">Content focused on selling products,
                          whether digital or physical</a-select-option>
                        <a-select-option :disabled="contextValue !== 3" :value="31">Application
                          store/marketplace</a-select-option>
                        <a-select-option :disabled="contextValue !== 3" :value="32">Product reviews site primarily (which
                          may sell product secondarily)</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Placement Type">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>The design/format/layout of the ad unit being offered.</span>
                      </template>
                      <a-select placeholder="Select Placement Type" v-decorator="['plcmtType', {
                        initialValue: nativeAdData.PlcmtType,
                      }]">
                        <a-select-option :disabled="false" :value="-1">Any</a-select-option>
                        <a-select-option :value="1">In the feed of content - for example as an item inside the organic
                          feed/grid/listing/carousel</a-select-option>
                        <a-select-option :value="2">In the atomic unit of the content - IE in the article page or single
                          image page</a-select-option>
                        <a-select-option :value="3">Outside the core content - for example in the ads section on the right
                          rail, as a banner - style placement near the content, etc.</a-select-option>
                        <a-select-option :value="4">Recommendation widget, most commonly presented below the article
                          content</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Limits</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Bids Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of bids per day. The system will even out distribution of bids throughout the day, ie. "day shaping". Set to 0 for unlimited bids.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Bids Daily Limit"
                        v-decorator="['bidsDailyLimit', { initialValue: nativeAdData.BidsDailyLimit }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Impressions Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of impressions per day. The system will even out distribution of impressions throughout the day, ie. "day shaping". Set to 0 for unlimited impressions.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Impressions Daily Limit"
                        v-decorator="['impressionsDailyLimit', { initialValue: nativeAdData.ImpressionsDailyLimit }]" />
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Clicks Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of clicks allowed per day. The system will even out distribution of clicks throughout the day, ie. "day shaping". Set to 0 for unlimited clicks.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Clicks Daily Limit"
                        v-decorator="['ClicksDailyLimit', { initialValue: nativeAdData.ClicksDailyLimit }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Spent Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum daily amount (USD) to spend on Native Ad. Set to 0 for unlimited amount.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Spent Daily Limit"
                        v-decorator="['SpentDailyLimit', { initialValue: nativeAdData.SpentDailyLimit }]" />
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Supply</a-divider>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="RTB Zones">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>RTB Zones to connect to the Native Ad. This will make the Native Ad appear in RTB Zone
                      response
                      if all the targeting is matched.</span>
                  </template>
                  <a-select
                    v-decorator="['Rtbzones', { initialValue: (nativeAdData.Rtbzones === '' ? [] : nativeAdData.Rtbzones) }]"
                    placeholder="Please select RTB Zones" :showSearch="true" :filterOption="true" mode="multiple"
                    optionFilterProp="children">
                    <a-select-option v-for="obj in activeRTBZones" class="zones" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}
                    </a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>
              <a-divider style="margin-top: 34px;">Event Trackers</a-divider>
              <div class="event-trackers-form">
                <a-tooltip placement="top">
                  <a-select v-model="trackerEvent" class="event-trackers-select" placeholder="Select Event">
                    <a-select-option :value="-1" disabled>Event</a-select-option>
                    <a-select-option label="Select Banner" :value="1">
                      Impression
                    </a-select-option>
                    <a-select-option label="Select Banner" :value="2">
                      50% in view for 1 second
                    </a-select-option>
                    <a-select-option label="Select Banner" :value="3">
                      100% in view for 1 second
                    </a-select-option>
                    <a-select-option label="Select Banner" :value="4">
                      Video 50% in view for 2 seconds
                    </a-select-option>
                  </a-select>
                </a-tooltip>
                <a-tooltip placement="top">
                  <a-select v-model="trackerMethod" class="event-trackers-select" placeholder="Select Method">
                    <a-select-option :value="-1" disabled>Method</a-select-option>
                    <a-select-option label="Select Banner" :value="1">
                      img
                    </a-select-option>
                    <a-select-option label="Select Banner" :value="2">
                      js
                    </a-select-option>
                  </a-select>
                </a-tooltip>

                <a-tooltip placement="top">
                  <a-input class="event-trackers-input" v-model="url" type="url" placeholder="URL" />
                </a-tooltip>

                <a-button class="px-2 py-0 submit-button" type="primary" @click="addEventTracker">Add +</a-button>
                <a-button class="mobile-submit-button" type="primary" @click="handleSubmit">Create Event</a-button>
              </div>
              <b-table :bordered="true" :fields="fields" :hover="true" :items="nativeAdData.EventTrackers" :small="true"
                :striped="true" show-empty stacked="md" class="mt-4">
                <template #cell(event)="data">
                  <div>
                    {{ eventTypes[data.item.event] }}
                  </div>
                </template>
                <template #cell(method)="data">
                  <div>
                    {{ methodTypes[data.item.method] }}
                  </div>
                </template>
                <template #cell(url)="data">
                  <div class="d-block d-sm-none">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>URL</span>
                      </template>
                      <a-input class="" placeholder="URL" v-model="data.item.url" size="small"
                        @search="updateEventUrl(data.item.url)">
                      </a-input>
                    </a-tooltip>
                  </div>
                  <div class="d-none d-sm-block">
                    <a-tooltip placement="top">
                      <a-input class="" placeholder="URL" v-model="data.item.url"
                        size="small" @search="updateEventUrl(data.item.Id, data.item.url)">
                      </a-input>
                    </a-tooltip>
                  </div>
                </template>
                <template #cell(actions)="data">
                  <b-button class="btn-info mr-1" size="sm" @click="deleteEventTracker(data.item)">
                    <i class="fa fa-trash"></i>
                  </b-button>
                </template>
              </b-table>
              <a-divider style="margin-top: 34px;">Native Assets</a-divider>
              <native-assets :id="nativeAdData.Id" :nativeassets="nativeAssets" :creatives="imageCreatives" :videoCreatives="videoCreatives" />
              <a-divider style="margin-top: 34px;">Categories & Ad Attributes</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad IAB Categories">
                    <a-tree checkable v-model="checkedComputedKeys" :auto-expand-parent="autoExpandParent"
                      :selected-keys="selectedKeys" :tree-data="treeData" @expand="onExpand" @select="onSelect"
                      optionFilterProp="children" />
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad Attributes">
                    <a-tree class="break-line" checkable v-model="checkedComputedAdKeys" :selected-keys="selectedAdKeys"
                      :tree-data="adTreeData" @expand="onExpand" @select="onAdSelect" optionFilterProp="children" />
                  </a-form-item>
                </div>
              </div>

              <a-divider style="margin-top: 34px;">Targeting</a-divider>
              <a-tabs default-active-key="1" class="mb-4">
                <a-tab-pane key="1" tab="Countries">
                  <a-row>
                    <a-input-search placeholder="Filter Countries" style="width: 200px" class="mb-2"
                      v-model="filterSearch" />
                    <label class="cpointer"><input type="checkbox" class="ml-5 cpos-half"
                        v-model="displayEnabledCountries" /> Display Only Enabled</label>
                    <label class="cpointer"><input type="checkbox" class="ml-5 cpos-half" v-model="selectAllCountries"
                        @click="handleCountries" /> De/Select All</label>
                  </a-row>
                  <a-spin class="d-flex justify-content-center" v-if="enabledCountries.length === 0">
                    <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
                  </a-spin>
                  <ul v-else class="list-group list-group-flush countryList" style="border: 1px solid #e4e9f0;">
                    <li class="list-group-item treeContainer" v-for="(item, index) in enabledCountries" :key="index"
                      :style="item.Enabled ? 'background-color: rgb(237 237 243 / 38%);' : ''">
                      <label class="col-sm cpos cpointer">
                        <input type="checkbox" class="global-checkbox" v-model="item.Enabled"
                          @click="enableCountry($event, item)" />
                        <span class="col-sm"><img :src="matchIcon(countries, item.CC)" /> {{ item.Country }} ({{
                          item.CC
                        }})</span>
                      </label>
                      <span class="col-sm cpos-half">
                        <label class="mr-1 no-margin">Bid Adjustment:
                          <span class="valuePadding"><input type="number" min="0" max="100"
                              class="form-control bid-adjustment ml-1" @keyup="adjustBid($event, item, null)"
                              v-model="item.BidAdjustment">%</span>
                        </label>
                      </span>
                      <span class="col-sm cpos">
                        <label for="" class="mr-1">Calculated Bid: </label>
                        <span class="calculatedBid">{{
                          typeof item.CalculatedBid === 'undefined' ? '' : Math.round(item.CalculatedBid * 100000) /
                        100000
                        }}</span>
                      </span>
                    </li>
                  </ul>
                </a-tab-pane>
                <a-tab-pane key="2" tab="Operating Systems" force-render>
                  <treeList :listData="nativeAdData.Os" type="os" v-model="OSData" :defaultVal="DefaultCPM"></treeList>
                </a-tab-pane>
                <a-tab-pane class="" style="overflow-x: scroll; overflow-y:hidden" key="3" tab="Browsers" force-render>
                  <treeList :listData="nativeAdData.Browsers" type="browser" v-model="BrowserData"
                    :defaultVal="DefaultCPM"></treeList>
                </a-tab-pane>
              </a-tabs>
              <a-divider class="mt-3"></a-divider>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Save Changes</button>
                  <button type="button"
                    :class="'btn btn-' + (nativeAdData.Status === 0 ? 'danger' : 'primary') + ' px-5 ml-0 ml-lg-2'"
                    @click.prevent="toggleAdStatus">
                    {{ nativeAdData.Status == 0 ? 'Deactivate' : 'Activate' }} Native Ad
                  </button>
                  <button type="button" class="btn px-5 ml-0 ml-lg-2" @click="goBack">Cancel</button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { Modal } from 'ant-design-vue'
import adTreeData from '@/views/display/data/blockedAds.json'
import treeList from '@/components/custom/TreeList'
import nativeAssets from '@/components/custom/native-assets'
import { getIabCategories } from '@/api/info'
import { getCreatives } from '@/api/display/creative.js'
import { getNativeAds, changeNativeAdStatus } from '@/api/display/nativead'
import staticCountryData from '@/resources/countries.json'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 5 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 16 },
}
const labelColCat = {
  xs: { span: 18 },
  sm: { span: 5 },
}
const wrapperColCat = {
  xs: { span: 24 },
  sm: { span: 10 },
}

export default {
  components: {
    treeList,
    nativeAssets,
  },
  computed: {
    ...mapState(['info', 'nativead', 'rtbzone', 'nativeasset']),
    ...mapGetters('rtbzone', ['getRTBZones', 'getActiveRTBZones']),
    activeRTBZones: function () {
      return this.getActiveRTBZones
    },
    checkedComputedKeys: {
      // getter
      get: function () {
        return this.treeData.length === 0 ? [] : this.checkedKeys
      },
      // setter
      set: function (newValue) {
        this.checkedKeys = newValue
      },
    },
    checkedComputedAdKeys: {
      // getter
      get: function () {
        return this.adTreeData.length === 0 ? [] : this.checkedAds
      },
      // setter
      set: function (newValue) {
        this.checkedAds = newValue
      },
    },
    linkClicktrackersString() {
      return this.nativeAdData.LinkClicktrackers.join('\n')
    },
    impTrackersString() {
      return this.nativeAdData.Imptrackers.join('\n')
    },
    enabledCountries: function () {
      if (this.displayEnabledCountries === true) {
        return this.countriesList.filter(el => el.Enabled === true)
      } else if (this.filterSearch !== '') {
        return this.countriesList.filter(el => {
          return el.Country.toLowerCase().indexOf(this.filterSearch.toLowerCase()) !== -1 ||
            el.CC.toLowerCase().indexOf(this.filterSearch.toLowerCase()) !== -1
        })
      }
      return this.countriesList
    },
    DefaultCPM: {
      get: function () {
        return this.nativeAdData.DefaultCPM
      },
      set: function (newValue) {
        if (!isNaN(parseFloat(newValue))) this.nativeAdData.DefaultCPM = parseFloat(newValue)
      },
    },
  },
  data() {
    return {
      countries: staticCountryData,
      form: this.$form.createForm(this),
      nativeAssets: [],
      checkedKeys: [],
      checkedAds: [],
      selectedAdKeys: [],
      imageCreatives: [],
      videoCreatives: [],
      autoExpandParent: false,
      selectedKeys: [],
      trackerEvent: -1,
      trackerMethod: -1,
      url: null,
      eventTrackers: [],
      eventTypes: { 1: 'Impression', 2: '50% in view for 1 second', 3: '100% in view for 1 second', 4: 'Video 50% in view for 2 seconds' },
      methodTypes: { 1: 'img', 2: 'js' },
      placement: null,
      treeData: [],
      filterSearch: '',
      adTreeData: adTreeData,
      fileList: [],
      linkClicktrackers: [],
      imptrackers: [],
      adCreated: false,
      nativeAdData: null,
      unformattedResponse: [],
      countriesList: [],
      selectAllCountries: false,
      contextValue: null,
      contextSubtype: null,
      columns: [
        {
          title: 'Preview',
          dataIndex: 'preview',
          key: 'preview',
        },
        {
          title: 'Action',
          dataIndex: '',
          align: 'center',
          key: 'x',
          scopedSlots: { customRender: 'action' },
        },
      ],
      bidAdjCountryColumns: [
        {
          title: 'Country',
          dataIndex: 'Country',
          key: 'Country',
        },
        {
          title: 'Enabled',
          dataIndex: 'Enabled',
          scopedSlots: { customRender: 'Enabled' },
        },
        {
          title: 'CC',
          dataIndex: 'CC',
          key: 'CC',
        },
        {
          title: 'Bid Adjustment',
          dataIndex: 'BidAdjustment',
          scopedSlots: { customRender: 'BidAdjustment' },
        },
        {
          title: 'Calculated Bid',
          dataIndex: 'CalculatedBid',
          scopedSlots: { customRender: 'CalculatedBid' },
        },
      ],
      bidAdjCountryFilter: '',
      countryPagination: { pageSize: 10 },
      OSData: null,
      BrowserData: null,
      labelCol,
      wrapperCol,
      labelColCat,
      wrapperColCat,
      displayEnabledCountries: false,
      fields: [
        {
          key: 'Event',
          label: 'Event',
          show: true,
          headerTitle: 'Event',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'width: 220px',
        },
        {
          key: 'Method',
          label: 'Method',
          show: true,
          headerTitle: 'Method',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'width: 80px',
        },
        {
          key: 'url',
          label: 'URL',
          show: true,
          headerTitle: 'URL',
          sortable: false,
          tdClass: 'align-middle',
          // thStyle: 'max-height:200px; width:220px;',
        },
        {
          key: 'actions',
          label: '',
          show: true,
          class: 'text-center',
          tdClass: 'align-middle',
          thStyle: 'width:50px',
        },
      ],
    }
  },
  methods: {
    isValidCombination(newEvent) {
      const existingCombinations = this.eventTrackers.map(event => `${event.event}-${event.method}`)
      const newCombination = `${newEvent.event}-${newEvent.method}`
      return !existingCombinations.includes(newCombination)
    },
    checkUrl(url) {
      var pattern = new RegExp('^https?:\\/\\/' + // this part explicitly requires "http://" or "https://"
        '(www\\.)?' +
        '([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}' +
        '(\\:[0-9]+)?' +
        '(\\/[a-zA-Z0-9\\-\\.\\_\\~\\:\\/\\?\\#\\[\\]\\@\\!\\$\\&\\\'\\(\\)\\*\\+\\,\\;\\=]*)?$',
      )
      return !!pattern.test(url)
    },
    addEventTracker() {
      if (this.trackerEvent && this.trackerMethod && this.url && this.trackerEvent !== -1 && this.trackerMethod !== -1) {
        if (!this.checkUrl(this.url)) {
          Vue.prototype.$notification.warning({
            message: 'URL not valid.',
            description: 'Please enter a valid URL.',
          })
          return
        }
        const newEvent = { event: this.trackerEvent, method: this.trackerMethod, url: this.url }
        if (this.isValidCombination(newEvent) && this.nativeAdData.EventTrackers.length < 8) {
          this.nativeAdData.EventTrackers.push(newEvent)
          this.trackerEvent = -1
          this.trackerMethod = -1
          this.url = ''
        } else {
          Vue.prototype.$notification.warning({
            message: 'This event-method combination already exists.',
          })
        }
      } else if (!this.url || this.trackerEvent === -1 || this.trackerMethod === -1) {
        Vue.prototype.$notification.warning({
          message: 'Not enough information.',
          description: 'Event, Method and URL are required.',
        })
      }
    },
    deleteEventTracker(target) {
      Modal.confirm({
        title: 'Are you sure you want to delete this event tracker?',
        onOk: () => {
          this.nativeAdData.EventTrackers = this.nativeAdData.EventTrackers.filter(item => item !== target)
        },
      })
    },

    updateEventUrl(url) {
    },
    matchIcon(array, code) {
      const cc = array.find(element => element.cc === code)
      if (cc === undefined) {
        return false
      } else {
        const image = cc.image
        return image
      }
    },
    handleContextChange() {
      if (this.contextValue === 1) {
        this.contextSubtype = 10
      } else if (this.contextValue === 2) {
        this.contextSubtype = 20
      } else if (this.contextValue === 3) {
        this.contextSubtype = 30
      }
    },
    handlelinkClicktrackers(data) {
      this.nativeAdData.LinkClicktrackers = data.join('\n')
    },
    handleImptrackers(data) {
      this.nativeAdData.Imptrackers = data.join('\n')
    },
    handleCountries() {
      this.countriesList.forEach(el => {
        el.Enabled = !this.selectAllCountries
      })
    },
    populateCountry: function () {
      if (this.countriesList.length > 0 && this.nativeAdData !== null && this.nativeAdData.Countries.length > 0) {
        for (const index in this.countriesList) {
          const country = this.nativeAdData.Countries.filter(ob => ob.CC === this.countriesList[index].CC)
          if (typeof country[0] !== 'undefined') {
            this.countriesList[index].Enabled = true
          }
        }
      }
    },
    // setAdPosition(key) {
    //   this.adPosition = key
    // },
    updateDefaultCPM(e) {
      this.DefaultCPM = e.target.value
      // const countryList = document.querySelectorAll('.countryList input[type=number]')
      // countryList.length > 0 && countryList.forEach(function(el, index) {
      //   this.adjustBid(null, this.countriesList[index], el)
      // }.bind(this))
      this.countriesList.forEach(el => {
        el.CalculatedBid = e.target.value * el.BidAdjustment / 100
        // this.nativeAdData.DefaultCPM = el.CalculatedBid
      })
    },
    getDimensions() {
      this.width = document.documentElement.clientWidth
      this.height = document.documentElement.clientHeight
    },
    adjustBid(event, row, obj = null) {
      const object = obj !== null ? obj : event.target
      const newBid = !isNaN(parseFloat(object.value)) ? parseFloat(object.value) : 0
      let itemIndex = this.countriesList.findIndex(el => el.CC === row.CC)
      if (itemIndex === -1) {
        this.countriesList.push({
          Country: row.Country,
          CC: row.CC,
          BidAdjustment: row.BidAdjustment,
          CalculatedBid: (this.nativeAdData.DefaultCPM * row.BidAdjustment / 100),
          Enabled: false,
        })
      }
      itemIndex = this.countriesList.findIndex(el => el.CC === row.CC)
      if (itemIndex !== -1) {
        this.countriesList[itemIndex].BidAdjustment = newBid
        this.countriesList[itemIndex].CalculatedBid = newBid === 0 ? 0 : this.nativeAdData.DefaultCPM * newBid / 100
      }
    },
    enableCountry(event, row) {
      const itemIndex = this.countriesList.findIndex(el => el.CC === row.CC)
      if (itemIndex === -1) {
        this.countriesList.push({
          Country: row.Country,
          CC: row.CC,
          BidAdjustment: row.BidAdjustment,
          CalculatedBid: (this.DefaultCPMliscountriesList * row.BidAdjustment / 100),
          Enabled: true,
        })
      } else {
        this.countriesList[itemIndex].Enabled = event.target.checked
      }
      // Recalculate bid
      const li = this.$_getContainer(event.target)
      const input = li.querySelector('input[type="number"]')
      this.adjustBid(null, row, input)
    },
    copyList(type) {
      if (type === 'linkClicktrackers') {
        navigator.clipboard.writeText(this.linkClicktrackersString)
        Vue.prototype.$notification.success({
          message: 'Link Clicktrackers copied to clipboard.',
        })
      } else if (type === 'impTrackers') {
        navigator.clipboard.writeText(this.impTrackersString)
        Vue.prototype.$notification.success({
          message: 'Imp. Trackers copied to clipboard.',
        })
      }
    },
    onAdSelect(selectedAdKeys, info) {
      // console.log('onSelect', info)
      this.selectedAdKeys = selectedAdKeys
    },
    onCheck(checkedKeys) {
      // console.log('onCheck', checkedKeys)
      this.checkedKeys = checkedKeys
    },
    onBlockedAdCheck(checkedAds) {
      // console.log('onCheck', checkedAds)
      this.checkedAds = checkedAds
    },
    onExpand(expandedKeys) {
      // console.log('onExpand', expandedKeys)
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    onSelect(selectedKeys, info) {
      // console.log('onSelect', info)
      this.selectedKeys = selectedKeys
    },
    goBack() {
      // this.$router.go(-1)
      this.$router.push({ name: 'nativeads' })
    },
    $_loadCreatives() {
      getCreatives().then(response => {
        this.unfilteredCreatives = response
        this.unfilteredCreatives.map((el) => {
          if (el.Type === 0) {
            this.imageCreatives.push(el)
          } else if (el.Type === 1) {
            this.videoCreatives.push(el)
          }
        })
        response.forEach(el => {
          if (this.nativeAdData.Creatives.includes(el.Id)) {
            this.receivedCreatives.push(el)
          }
        })
      })
    },
    handleSubmit() {
      const countries = []
      this.countriesList.forEach(el => {
        if (el.Enabled) {
          countries.push({
            Country: el.Country,
            CC: el.CC,
            BidAdjustment: el.BidAdjustment,
            CalculatedBid: el.CalculatedBid,
          })
        }
      })
      const oss = this.$_formatTreeData('os')
      const browsers = this.$_formatTreeData('browser')
      this.form.validateFields((err, values) => {
        if (!err) {
          const parentSelectedIab = this.checkedKeys.filter(checkedKey => this.unformattedResponse.includes(checkedKey))
          const array = this.treeData.map(element => parentSelectedIab.includes(element.key) ? element.children : []).flat().map(el => el.key)
          const newArr = this.checkedKeys.filter(el => !array.includes(el))
          values.Placement = this.placement
          values.AdIabCategories = newArr
          values.AdAttributes = this.checkedAds
          values.Id = this.nativeAdData.Id
          values.LinkClicktrackers = this.nativeAdData.LinkClicktrackers
          values.Imptrackers = this.nativeAdData.Imptrackers
          values.Context = this.contextValue
          values.ContextSubtype = this.contextSubtype
          values.EventTrackers = this.nativeAdData.EventTrackers
          if (values.destinationURL !== '' && values.destinationURL) {
            values.destinationURL = values.destinationURL.replace(/\s/g, '')
          }
          if (values.linkFallback !== '' && values.linkFallback) {
            values.linkFallback = values.linkFallback.replace(/\s/g, '')
          }
          if (values.privacyUrl !== '' && values.privacyUrl) {
            values.privacyUrl = values.privacyUrl.replace(/\s/g, '')
          }
          this.$store.dispatch('nativead/UPDATE_AD', {
            id: this.nativeAdData.Id,
            payload: {
              ...values,
              DefaultCPM: this.DefaultCPM,
              Browsers: browsers,
              Os: oss,
              Countries: countries,
            },
            callback: function () {
              this.$router.push({ name: 'nativeads' })
            }.bind(this),
            // @click="$router.push('/ads')"
          })
        }
      })
    },

    $_formatTreeData(type = 'os') {
      const searchData = type === 'os' ? this.OSData : this.BrowserData
      const formatedData = []
      searchData.forEach(el => {
        // Add if all versions selected
        if (el.enabled) {
          const temp = {
            Family: el.Family,
            BidAdjustment: el.BidAdjustment,
          }
          if (el.Versions.length > 0) {
            const tmpV = []
            el.Versions.forEach(ss => {
              if (ss.enabled) {
                const temp2 = {
                  BidAdjustment: ss.BidAdjustment,
                  Major: ss.Major,
                }
                if (typeof ss.Minor !== 'undefined') temp2.Minor = ss.Minor
                tmpV.push(temp2)
              }
            })
            temp.Versions = tmpV
          }
          if (typeof el.AutoIncludeNewBrowsers !== 'undefined') temp.AutoIncludeNewBrowsers = el.AutoIncludeNewBrowsers ? 1 : 0
          formatedData.push(temp)
          return
        }
        // Filter versions
        const versions = []
        el.Versions.length > 0 && el.Versions.forEach(ver => {
          if (ver.enabled) {
            const temp = {
              BidAdjustment: ver.BidAdjustment,
              Major: ver.Major,
            }
            if (typeof ver.Minor !== 'undefined') temp.Minor = ver.Minor
            versions.push(temp)
          }
        })
        // Add if one or more versions is used
        if (versions.length > 0) {
          const tmp = {
            Family: el.Family,
            BidAdjustment: el.BidAdjustment,
            Versions: versions,
          }
          if (typeof el.AutoIncludeNewBrowsers !== 'undefined') tmp.AutoIncludeNewBrowsers = el.AutoIncludeNewBrowsers ? 1 : 0
          formatedData.push(tmp)
        }
      })
      return formatedData
    },
    $_getContainer(el, tag = 'LI') {
      return el.tagName === tag ? el : this.$_getContainer(el.parentElement, tag)
    },
    defaultCpmValidator(rule, value, message) {
      if (value === '') {
        message()
      } else if (isNaN(parseFloat(value))) {
        message('Invalid number.')
      } else if (parseFloat(value) < 0) {
        message('Default CPM can not be negative.')
      }
      message()
    },
    renderBoolean(array) {
      if (array.length === 0) {
        return false
      } else {
        return true
      }
    },
    toggleAdStatus() {
      const status = this.nativeAdData.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change Native Ad status?',
        content: h => <div>{status} Banner Ad <strong></strong>?</div>,
        onOk: function () {
          const newStatus = this.nativeAdData.Status === 0 ? 1 : 0
          changeNativeAdStatus({
            id: this.nativeAdData.Id,
            status: newStatus,
          }).then((response) => {
            this.nativeAdData.Status = response.Nativead.Status
            Vue.prototype.$notification.success({
              message: (response.Nativead.Status === 2 ? 'Native Ad Deleted' : 'Status Changed'),
              description: (response.Nativead.Status === 2 ? 'Native Ad successfully deleted.' : 'Native Ad status successfully changed.'),
            })
          })
        }.bind(this),
      })
    },
  },
  watch: {
    nativeAdData(val) {
      if (val !== undefined) {
        this.checkedKeys = val.AdIabCategories
        this.checkedComputedAdKeys = val.AdAttributes
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.getDimensions)
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions)
  },
  created() {
    // this.$store.dispatch('info/LOAD_COUNTRIES')
    this.$store.dispatch('info/LOAD_OSS')
    this.$store.dispatch('info/LOAD_BROWSERS')
    this.$store.dispatch('rtbzone/LOAD_RTBZONES_SHORT')
    getIabCategories().then(response => {
      response.forEach(element => {
        this.unformattedResponse.push(element.Name)
      })
      const formattedResponse = []
      response.forEach((el, id) => {
        const temp = {
          title: '(' + el.Name + ')' + ' ' + el.Title,
          key: el.Name,
          children: [],
        }
        el.Subcategories.forEach((element) => {
          temp.children.push({
            title: '(' + element.Name + ')' + ' ' + element.Title,
            key: element.Name,
          })
        })
        formattedResponse.push(temp)
        this.treeData = formattedResponse
      })
    })
    getNativeAds(this.$route.params.id).then((response) => {
      this.nativeAdData = response
      this.checkedKeys = this.nativeAdData.AdIabCategories
      this.checkedComputedAdKeys = this.nativeAdData.AdAttributes
      this.contextValue = this.nativeAdData.Context
      this.contextSubtype = this.nativeAdData.ContextSubtype
      this.nativeAssets = response.NativeAssets
      this.$_loadCreatives()
      if (this.nativeAdData.EventTrackers !== {}) {
        this.eventTrackers = this.nativeAdData.EventTrackers
      }
      this.populateCountry()
    })
      .then(() => {
        this.$store.dispatch('info/LOAD_COUNTRIES').then((res) => {
          res.forEach((el, index) => {
            const country = this.nativeAdData.Countries.filter(ob => ob.CC === el.CC)
            if (typeof country[0] !== 'undefined') {
              if (typeof country[0].Enabled === 'undefined') country[0].Enabled = true
              this.countriesList.push(country[0])
              return false
            }
            this.countriesList.push({
              Country: el.Country,
              CC: el.CC,
              Enabled: false,
              BidAdjustment: 100,
              CalculatedBid: this.nativeAdData.DefaultCPM,
            })
          })
          // this.populateCountry()
        })
      })
  },
}
</script>
<style lang="css" scoped>
.valuePadding {
  background-color: #ffffff;
  border-radius: 4px;
  padding-right: 5px;
  border: #e9edf3 1px solid;
}

.valuePadding input {
  border: none;
  background-color: transparent;
  margin-top: 0;
}

.valuePadding .bid-adjustment {
  padding-bottom: 0px;
  padding-top: 0px;
}

.ant-form-item {
  margin-bottom: 5px !important;
}

.break-line>>>li .ant-tree-node-content-wrapper {
  margin-bottom: 5px;
  padding-top: 4px;
  display: inline-block !important;
  white-space: pre-wrap !important;
  line-height: 15px !important;
  width: 90% !important;
}

.pointer {
  cursor: pointer;
}

.box {
  height: 8em;
  width: 8em;
  /* border: 0; */
  overflow: none;
  padding: 0;
  background-color: #f0f2f4;
}

.selected-outline {
  outline: thick solid #e5471c
}

.header-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
}

.header-bottom {
  height: 5em;
  margin-top: 3em;
}

.select-container {
  display: flex;
  justify-content: center;
  color: #f0f2f4;
}

.header-split {
  height: 3em;
  width: 8em;
  position: relative;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}

.header-top {
  top: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #557df6;
}

.header-bottom {
  bottom: 0;
  background-color: none;
}

.footer-split {
  display: inline-flex;
  align-self: flex-end;
  height: 3em;
  width: 8em;
  position: relative;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}

.footer-top {
  top: 63%;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #557df6;
}

.footer-bottom {
  bottom: 0;
  background-color: none;
}

.sidebar-split {
  height: 8em;
  width: 3em;
  position: relative;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}

.sidebar-left {
  left: 63%;
  writing-mode: vertical-lr;
  text-orientation: sideways-right;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #557df6;
}

.sidebar-right {
  right: 0;
  background-color: none;
}

.above-split {
  height: 5em;
  width: 8em;
  position: relative;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}

.above-top {
  top: 0;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  background-color: #557df6;
}

.above-bottom {
  bottom: 0;
  background-color: none;
}

.below-split {
  display: inline-flex;
  align-self: flex-end;
  height: 5em;
  width: 8em;
  position: relative;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}

.below-top {
  top: 38%;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #557df6;
}

.below-bottom {
  bottom: 0;
  background-color: none;
}

.list-flex {
  display: flex;
  align-content: flex-start;
}

.copy-button-width {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.1rem !important;
  height: 2.1rem !important;
}

@media screen and (min-width: 1208px) {
  .event-trackers-form {
    display: flex;
    justify-content: center;
  }

  .event-trackers-select {
    min-width: 15em;
    max-width: 18em;
    margin-right: 0.5em;
  }

  .event-trackers-input {
    max-width: 15em;
    margin-right: 0.5em;
  }

  .custom-width {
    max-width: 15em;
  }

  .submit-button {
    display: block;
  }

  .mobile-submit-button {
    display: none;
  }
}

@media screen and (max-width: 1208px) {
  .submit-button {
    display: none;
  }

  .mobile-submit-button {
    display: block;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .w-45 {
    width: 45% !important;
  }

  .flex {
    display: flex;
    flex-direction: col;
    width: 100%;
  }
}

;

@media only screen and (max-width: 768px) {
  .mobile-mb {
    margin-bottom: 1em;
  }

  .-m {
    margin-right: -4rem;
  }

  .centered {
    justify-content: space-evenly;
  }

}

.box {
  height: 8em;
  width: 8em;
  border: 0;
  padding: 0;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  background: #f0f2f4;
}

;

.custom-table th,
.custom-table td {
  padding: 0 0 0 6px !important;
  height: 30;
  text-align: center;
}

.no-records-to-show {
  text-align: center;
  text-decoration: none;
  font-weight: normal;
}

.ant-table-small {
  border: none !important;
}

/** */
.arrow.left::before {
  content: "▶";
  height: 10px;
  margin-right: 5px;
}

.arrow.down::before {
  content: "▼";
  height: 10px;
  margin-right: 5px;
}

.bid-adjustment {
  display: inline;
  width: 80px !important;
  padding: 0 5px;
  font-size: 0.9rem;
  height: 1.7rem;
}

.checkbox {
  position: relative;
  top: 2px;
}

.list-group-item {
  padding: .1rem 1.25rem !important;
}

.treeContainer {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
}

.treeContainerV {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  /* flex-wrap: wrap; */
}

label {
  opacity: 0.8;
}

.no-border {
  border: none;
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 1px !important;
}

.global-checkbox {
  position: relative;
  top: 2px;
}

.cpos-half {
  position: relative;
  top: 2px;
}

.cpos {
  position: relative;
  top: 4px;
}

.version-checkbox {
  position: relative;
  margin-right: 5px;
  top: 2px;
}

.no-margin {
  margin-bottom: 0;
}

.cpointer {
  cursor: pointer;
}
</style>
